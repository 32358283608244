// Imports from respective modules
import  { DataProcessing, PDS, Preferences, QA}  from "@mydex/pds-adaptor-frontend";
import  { MRD, Lists }  from "@mydex/mrd-adaptor-frontend";
import  { InAppDatasets }  from "@mydex/inapp-datasets";
import  { Util, Validation }   from "@mydex/utils";
import  { Dashboard }   from "@mydex/dashboards";
// Import Design System Components
import {
  Card,
  PasswordChange,
  PDSTable,
  Forms,
  Export,
  ProfileImage,
  PasswordReset,
  PrivateKeyChange,
  RemoveConnection,
  Account,
  Timezone,
  Timeout,
}  from "@mydex/mydex-design-system";

// Loads code into the window context so can be references anywhere
window.DataProcessing = DataProcessing;
window.MRD = MRD;
window.Lists = Lists;
window.InAppDatasets = InAppDatasets;
window.Util = Util;
window.Validation = Validation;
window.Dashboard = Dashboard;
window.Account = Account;
window.Timeout = Timeout;
window.Timezone = Timezone;
window.PDS = PDS;

window.addEventListener("load", Card.init, false);
window.addEventListener("load", PasswordChange.init, false);
window.addEventListener("load", PasswordReset.init, false);
window.addEventListener("load", PDSTable.init, false);
window.addEventListener("load", Export.init, false);
window.addEventListener("load", Forms.init, false);
window.addEventListener("load", DataProcessing.load.pds, false);
window.addEventListener("load", ProfileImage.init, false);
window.addEventListener("load", RemoveConnection.init, false);
window.addEventListener("load", PrivateKeyChange.init, false);
window.addEventListener("load", Account.init, false);
window.addEventListener("load", Timeout.init, false);
window.addEventListener("load", Timezone.init, false);

// Add event listener for the JSON datasets
function addJSONReadEventListeners() {
  $('.pds-json-instance').each(function () {
    $(this).click(function () {
      // Get the dataset and the instance from the link we clicked
      var dataset = $(this).attr("data-dataset");
      var instance = $(this).attr("data-instance");
      // Load the data from PDS and display
      DataProcessing.load.displayAllDatasets('/pds-json-read', dataset, null, instance);
    });
  });
}
window.addEventListener("load", addJSONReadEventListeners, false);
